<template>
  <v-select
    v-model="selected"
    :loading="loading"
    :items="warehouses"
    :label="label"
    item-text="name"
    item-value="id"
    dense
    :outlined="outlined"
    :rules="rules"
    return-object
  />
</template>

<script>
import WarehouseServices from '@/store/services/warehouse/WarehouseServices';
import { mapGetters } from 'vuex';
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: '',

      loading: false,
      warehouses: [],
    };
  },
  mounted() {
    this.getWarehouses();
  },
  methods: {
    async getWarehouses() {
      try {
        this.loading = true;
        const params = {
          order_by: 'parent_id',
          order_direction: 'ASC',
        };
        const response = await WarehouseServices.index(this.user.archon.id, params);
        this.warehouses = response.data.data;
        const warehouseId = this.user.warehouse.id;
        const index = this.warehouses.findIndex(warehouse => warehouse.id === warehouseId);
        this.warehouses.splice(index, 1);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  watch: {
    selected() {
      this.$emit('input', this.selected);
    },
  },
};
</script>