<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
          dense
        >
          <v-btn
            icon
            dark
            @click="dialog=false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>

        </v-toolbar>
        <iframe v-if="fileUrl" id="ifrm" :src="fileUrl" width="100%" height="650"></iframe>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: 'FramePdf',
  props: {
    // dialog: {
    //   type: Boolean,
    //   default: false
    // },
    fileUrl: {
      default: null
    },
    title: {
      type: String,
      default: 'Reports'
    }
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
    closeDialog() {
      this.$emit('onClose')
    },
    setDialog() {
      if (this.fileUrl) {
        this.dialog = true
      }
    }
  },
  watch: {
    fileUrl: {
      immediate:true,
      handler: 'setDialog'
    }
  }
}
</script>

<style>

</style>
