<template>
  <div>

    <v-row>
      <v-col
        cols="12"
        sm="12"
        md="4"
      >
        <SelectWarehouse
          v-model="form.warehouse"
        />
      </v-col>
      
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
      <v-btn
        color="success"
        @click="getReport"
      >
        imprimir
      </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import InventoryServices from '@/store/services/inventory/InventoryServices'
import SelectWarehouse from '../../../components/commons/SelectWarehouse'
import { mapGetters } from 'vuex';
export default {
  components: {
    SelectWarehouse
  },
  data() {
    return {
      menuFrom: false,
      menuTo: false,
      form: {
        date_from: '',
        date_to: '',
        warehouse: ''
      },
      params: {},
      fileUrl: null,
    }
  },
  methods: {
    async getReport() {
      try {
        this.loading = true
        let response = await InventoryServices.reports(this.user.archon.id, this.form.warehouse.id, {
          params: this.form,
          responseType: 'blob',
          Accept: 'application/pdf',
        })
        const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        this.fileUrl = url
        this.$emit('onReport', url)
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  }
}
</script>

<style>

</style>