<template>
  <div>
    <v-select
      :items="items"
      :label="label"
      :dense="dense"
      :outlined="outlined"
      @change="changeSelected"
    />
  </div>
</template>

<script>
export default {
  props: {
    dense: {
      default: false
    },
    label: {
      default: 'Tipo de Orden'
    },
    outlined: {
      default: false
    }
  },
  data() {
    return {
      items: [
        {
          value: 'PURCHASE',
          text: this.$t('PURCHASE')
        },
        {
          value: 'DISPATCH',
          text: this.$t('DISPATCH')
        },
        {
          value: 'RETURN',
          text: this.$t('RETURN')
        },
        {
          value: 'SALE',
          text: this.$t('SALE')
        },
        // {
        //   value: 'BUY',
        //   text: this.$t('BUY')
        // },
        // {
        //   value: 'RESTORE',
        //   text: this.$t('RESTORE')
        // },
        {
          value: 'SALE_PAYMENT',
          text: this.$t('SALE_PAYMENT')
        },
      ]
    }
  },
  methods: {
    changeSelected(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>

</style>