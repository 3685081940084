<template>
    <v-card
      class="mx-auto mb-4"
      max-width="600"
    >
      <Loader :loading="loading" />

      <v-list>
        <v-list-group
          no-action
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Ordenes</v-list-item-title>
            </v-list-item-content>
          </template>
  
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="4"
                >
                  <SelectOrderType
                    v-model="form.order_type"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-menu
                    v-model="menuFrom"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date_from"
                        label="del:"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date_from"
                      @input="menuFrom = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-menu
                    v-model="menuTo"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="form.date_to"
                        label="al:"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="form.date_to"
                      @input="menuTo = false"
                      locale="es"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                <v-btn
                  color="success"
                  @click="getReport"
                >
                  imprimir
                </v-btn>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Inventario</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item>
          <v-list-item-content>
            <FormInventory
              @onReport="setReport"
            ></FormInventory>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      </v-list>
      <FramePdf
        :fileUrl="fileUrl"
      />
    </v-card>
  </template>
  
  <script>
  import OrderServices from '@/store/services/warehouse/OrderServices'
  import FramePdf from '../../general/reports/FramePdf';
  import SelectOrderType from '../../warehouse/order/SelectOrderType';
  import FormInventory from '../inventory/FormInventory';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      FramePdf,
      SelectOrderType,
      FormInventory
    },
    data() {
      return {
        menuFrom: false,
        menuTo: false,
        form: {
          date_from: '',
          date_to: '',
          order_type: ''
        },
        params: {},
        fileUrl: null,
        loading: false
      }
    },
    methods: {
      async getReport() {
        try {
          this.loading = true
          let response = await OrderServices.reports(this.user.archon.id, {
            params: this.form,
            responseType: 'blob',
            Accept: 'application/pdf',
          })
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          this.fileUrl = url
          this.loading = false
        } catch (error) {
          this.loading = false
          console.log(error)
        }
      },
      setReport(report) {
        this.fileUrl = report
      }
    },
    computed: {
      ...mapGetters({
        user: 'getUser',
      }),
    }
  }
  </script>
  
  <style>
  
  </style>